<template>
  <b-modal
    id="delete-review-popup"
    :visible="visible"
    size="md"
    ok-title="Удалить"
    cancel-title="Отменить"
    ok-variant="danger"
    :title="`Удалить отзыв «${review.name}»?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Review from '@/api/http/models/review/Review'

export default {
  props: {
    reviewId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      review: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Review.getOne(this.currentSite.slug, this.reviewId)

    this.review = response.data || {}

    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Review.delete(this.currentSite.slug, this.reviewId)

        if (response.status === 'success') {
          this.$emit('deleted', this.review)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Отзыв «${this.review.name}» удален`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('delete-review-popup')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Ошибка при удалении отзыва «${this.review.name}»`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
