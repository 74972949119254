<template>
  <b-modal
    id="add-review-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3">
        <div>
          <b-form-group
            label="Дата отзыва"
            label-for="date"
          >
            <div class="flat-pickr-wrap">
              <flat-pickr
                v-model="editReview.date"
                v-validate="'required'"
                :config="datePickerConfig"
                placeholder="Дата отзыва"
                name="date"
              />
              <small
                v-show="errors.has('date')"
                class="text-danger"
              >{{ errors.first('date') }}</small>
            </div>
          </b-form-group>
          <b-form-group
            label="Имя клиента"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="editReview.name"
              name="name"
            />
          </b-form-group>
          <b-form-group
            label="Отзыв"
            label-for="text"
          >
            <b-form-textarea
              id="text"
              v-model="editReview.text"
              v-validate="'required'"
              name="text"
            />
            <small
              v-show="errors.has('text')"
              class="text-danger"
            >{{ errors.first('text') }}</small>
          </b-form-group>
          <b-form-group
            label="Тип отзыва"
            label-for="type"
          >
            <Select2
              id="type"
              v-model="editReview.type"
              :options="reviewsTypeOptions"
              :settings="{minimumResultsForSearch: -1}"
              name="type"
            />
            <small
              v-show="errors.has('type')"
              class="text-danger"
            >{{ errors.first('type') }}</small>
          </b-form-group>
          <b-form-group
            label="Оценка (количество звёзд)"
            label-for="type"
          >
            <StarRating
              v-model="editReview.mark"
              :star-size="30"
            />
          </b-form-group>
          <b-form-group
            label="Локация отзыва"
            label-for="location"
          >
            <Select2
              id="location"
              v-model="editReview.site_contact_id"
              :options="contactsOptions"
              :settings="{minimumResultsForSearch: -1, allowClear: true}"
              name="location"
            />
            <small
              v-show="errors.has('location')"
              class="text-danger"
            >{{ errors.first('location') }}</small>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BFormGroup, BFormInput, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import { mapGetters } from 'vuex'
import 'flatpickr/dist/flatpickr.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Select2 from 'v-select2-component'
import StarRating from 'vue-star-rating'
import Review from '@/api/http/models/review/Review'
import Contact from '@/api/http/models/contact/Contact'
import moment from 'moment'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    Select2,
    StarRating,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [mixinErrorsParse],

  props: {
    reviewId: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: 'Редактирование отзыва',
    },
  },

  data() {
    return {
      visible: false,
      editReview: {},
      review: {},
      datePickerConfig: {
        wrap: true,
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        locale: Russian,
      },
      reviewsTypeOptions: [
        {
          id: 'yandex',
          text: 'Yandex',
        },
        {
          id: 'google',
          text: 'Google',
        },
        {
          id: '2gis',
          text: '2GIS',
        },
        {
          id: 'zoon',
          text: 'Zoon',
        },
      ],
      reviewsMarkOptions: [
        {
          id: '1',
          text: '1',
        },
        {
          id: '2',
          text: '2',
        },
        {
          id: '3',
          text: '3',
        },
        {
          id: '4',
          text: '4',
        },
        {
          id: '5',
          text: '5',
        },
      ],
      contactsOptions: [],
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    const responseContacts = await Contact.getAll(this.currentSite.slug)

    if (responseContacts.data) {
      this.contactsOptions = responseContacts.data.map(contact => ({
        id: contact.id,
        text: `${contact.city} ${contact.address}`,
      }))
    }

    const response = await Review.getOne(this.currentSite.slug, this.reviewId)

    this.review = response.data || {}

    await this.prepareReviewObject()

    this.visible = true
  },

  methods: {
    prepareReviewObject() {
      this.editReview = JSON.parse(JSON.stringify(this.review))
      this.editReview.date = moment(this.editReview.date).format('YYYY-MM-DD')
    },

    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Review.update(this.currentSite.slug, this.review.id, {
          ...this.editReview,
          site_contact_id: +this.editReview.site_contact_id || null,
          date: new Date(this.editReview.date).toISOString(),
        })

        if (response.status === 'success' && response.data) {
          this.$emit('updated', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Отзыв обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-review-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при редактировании отзыва',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      }
    },
  },
}
</script>

<style lang="scss">
.flat-pickr-wrap .input {
  background: #fff;
}
.select2-selection__arrow {
  display: none;
}
</style>
