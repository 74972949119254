import HTTPService from '@/api/http/HTTPService'
import URLSearchParams from '@/@core/utils/getUrlParams'
import ReviewInterface from '@/api/http/models/review/ReviewInterface'

export default {
  getAll: (siteSlug: string, query?: {page?: number, search?: string}) => {
    const queryString = query ? URLSearchParams(query) : false
    return HTTPService.get(`site/${siteSlug}/review${queryString ? `?${queryString}` : ''}`)
  },
  add: (siteSlug: string, payload: ReviewInterface) => HTTPService.post(`site/${siteSlug}/review`, payload),
  getOne: (siteSlug: string, reviewId: string) => HTTPService.get(`site/${siteSlug}/review/${reviewId}`),
  update: (siteSlug: string, reviewId: string, payload: ReviewInterface) => HTTPService.put(`site/${siteSlug}/review/${reviewId}`, payload),
  delete: (siteSlug: string, reviewId: string) => HTTPService.delete(`site/${siteSlug}/review/${reviewId}`),
}
