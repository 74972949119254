<template>
  <b-card>
    <div class="row mb-5">
      <div class="col-12 col-md-3">
        <b-form-input
          id="search"
          v-model="search"
          name="search"
          placeholder="Поиск"
          @keyup.enter="onSearch"
          @input="onInputSearch"
        />
      </div>
    </div>
    <b-button
      class="mb-2 d-flex align-items-center"
      variant="primary"
      @click.prevent="showAddPopup = true"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="mr-1"
      />
      Добавить отзыв
    </b-button>
    <div
      v-if="reviews.length"
    >
      <reviews-list
        :reviews="reviews"
        @updated="updateReview"
        @deleted="deleteReview"
      />
      <b-pagination
        v-if="total / per_page > 1"
        v-model="page"
        :total-rows="total"
        :per-page="per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        @input="onChangePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div v-else>
      Нет отзывов
    </div>
    <add-review-popup
      v-if="showAddPopup"
      @added="addReview"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton, BFormInput, BPagination,
} from 'bootstrap-vue'

import Review from '@/api/http/models/review/Review'

import AddReviewPopup from './AddReviewPopup.vue'
import ReviewsList from './ReviewsList.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BPagination,
    AddReviewPopup,
    ReviewsList,
  },
  data() {
    return {
      showAddPopup: false,
      reviews: [],
      search: '',
      page: 1,
      per_page: 1,
      total: 1,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.page = this.$route.query.page || 1
    this.search = this.$route.query.search || ''

    await this.fetchReviews()
  },
  methods: {
    async fetchReviews() {
      const query = {}

      if (this.page) {
        query.page = this.page
      }

      if (this.search) {
        query.search = this.search
      }

      const response = await Review.getAll(this.currentSite.slug, query)

      this.reviews = response.data || []

      this.per_page = response.meta.per_page || 1

      this.total = response.meta.total || 1
    },
    onInputSearch() {
      if (this.search === '') {
        this.onSearch()
      }
    },
    onSearch() {
      const query = {}

      if (this.search) {
        query.search = this.search
      }

      if (
        JSON.stringify(this.$route.query) !== JSON.stringify(query)
      ) {
        this.page = 1

        this.$router.replace({
          query: {
            ...query,
          },
        })

        this.fetchReviews()
      }
    },
    onChangePage() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      })

      this.fetchReviews()
    },
    addReview(review) {
      this.reviews.push(review)
    },
    updateReview(review) {
      const index = this.reviews.findIndex(cur => cur.id === review.id)

      if (index !== -1) {
        this.reviews.splice(index, 1, review)
      }
    },
    deleteReview(review) {
      const index = this.reviews.findIndex(cur => cur.id === review.id)

      if (index !== -1) {
        this.reviews.splice(index, 1)
      }
    },
  },
}
</script>
