<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-12 col-lg-6">
          Имя
        </div>
        <div class="col-12 col-lg-2">
          Дата
        </div>
        <div class="col-12 col-lg-2">
          Оценка
        </div>
        <div class="col-12 col-lg-2 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="review in reviews"
        :key="review.id"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-6">
                {{ review.name }}
              </div>
              <div class="col-12 col-lg-2">
                {{ review.date | momentFormat }}
              </div>
              <div class="col-12 col-lg-2">
                <StarRating
                  v-model="review.mark"
                  :star-size="20"
                  :read-only="true"
                  :show-rating="false"
                />
              </div>
              <div class="col-12 col-lg-2">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(review)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    @click.prevent="openDeletePopup(review)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-review-popup
      v-if="showEditPopup && currentReviewId"
      :key="currentReviewId"
      :review-id="currentReviewId"
      @updated="$emit('updated', $event)"
      @hidden="hideEditPopup"
    />
    <delete-review-popup
      v-if="showDeletePopup && currentReviewId"
      :key="currentReviewId"
      :review-id="currentReviewId"
      @deleted="$emit('deleted', $event)"
      @hidden="hideDeletePopup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import StarRating from 'vue-star-rating'
import EditReviewPopup from './EditReviewPopup.vue'
import DeleteReviewPopup from './DeleteReviewPopup.vue'

export default {
  components: {
    BButton,
    EditReviewPopup,
    DeleteReviewPopup,
    StarRating,
  },
  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showDeletePopup: false,
      currentReviewId: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  methods: {
    openEditPopup(review) {
      this.showEditPopup = true
      this.currentReviewId = review.id
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.currentReviewId = null
    },
    openDeletePopup(review) {
      this.showDeletePopup = true
      this.currentReviewId = review.id
    },
    hideDeletePopup() {
      this.showDeletePopup = false
      this.currentReviewId = null
    },
  },
}
</script>
