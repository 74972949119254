<template>
  <b-modal
    id="add-review-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3">
        <div>
          <b-form-group
            label="Дата отзыва"
            label-for="date"
          >
            <div class="flat-pickr-wrap">
              <flat-pickr
                v-model="review.date"
                :config="datePickerConfig"
                placeholder="Дата отзыва"
              />
              <small
                v-show="errors.has('date')"
                class="text-danger"
              >{{ errors.first('date') }}</small>
            </div>
          </b-form-group>
          <b-form-group
            label="Имя клиента"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="review.name"
              name="name"
            />
          </b-form-group>
          <b-form-group
            label="Отзыв"
            label-for="text"
          >
            <b-form-textarea
              id="text"
              v-model="review.text"
              v-validate="'required'"
              name="text"
            />
            <small
              v-show="errors.has('text')"
              class="text-danger"
            >{{ errors.first('text') }}</small>
          </b-form-group>
          <b-form-group
            label="Тип отзыва"
            label-for="type"
          >
            <Select2
              id="type"
              v-model="review.type"
              :options="reviewsTypeOptions"
              :settings="{minimumResultsForSearch: -1}"
              name="type"
            />
            <small
              v-show="errors.has('type')"
              class="text-danger"
            >{{ errors.first('type') }}</small>
          </b-form-group>
          <b-form-group
            label="Оценка (количество звёзд)"
            label-for="mark"
          >
            <StarRating
              v-model="review.mark"
              :star-size="30"
            />
          </b-form-group>
          <b-form-group
            label="Локация отзыва"
            label-for="location"
          >
            <Select2
              id="location"
              v-model="review.site_contact_id"
              :options="contactsOptions"
              :settings="{minimumResultsForSearch: -1, allowClear: true}"
              name="location"
            />
            <small
              v-show="errors.has('location')"
              class="text-danger"
            >{{ errors.first('location') }}</small>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BFormGroup, BFormInput, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import { mapGetters } from 'vuex'
import 'flatpickr/dist/flatpickr.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Select2 from 'v-select2-component'
import StarRating from 'vue-star-rating'
import Review from '@/api/http/models/review/Review'
import Contact from '@/api/http/models/contact/Contact'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    Select2,
    StarRating,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [mixinErrorsParse],

  props: {
    title: {
      type: String,
      default: 'Добавление отзывы',
    },
  },

  data() {
    return {
      visible: false,
      review: {
        date: new Date().toDateString(),
        name: '',
        text: '',
        type: 'yandex',
        mark: 5,
        site_contact_id: null,
      },
      datePickerConfig: {
        wrap: true,
        altFormat: 'd.m.Y',
        altInput: true,
        defaultDate: 'today',
        maxDate: 'today',
        locale: Russian,
      },
      reviewsTypeOptions: [
        {
          id: 'yandex',
          text: 'Yandex',
        },
        {
          id: 'google',
          text: 'Google',
        },
        {
          id: '2gis',
          text: '2GIS',
        },
        {
          id: 'zoon',
          text: 'Zoon',
        },
      ],
      contactsOptions: [],
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    const responseContacts = await Contact.getAll(this.currentSite.slug)

    if (responseContacts.data) {
      this.contactsOptions = responseContacts.data.map(contact => ({
        id: contact.id,
        text: `${contact.city} ${contact.address}`,
      }))
    }

    this.visible = true
  },

  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Review.add(this.currentSite.slug, {
          ...this.review,
          site_contact_id: +this.review.site_contact_id || null,
          date: this.review.date,
        })

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Отзыв добавлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-review-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении отзыва',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      }
    },
  },
}
</script>

<style lang="scss">
.flat-pickr-wrap .input {
  background: #fff;
}
.select2-selection__arrow {
  display: none;
}
</style>
